'use client';

import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
  Dispatch,
  Suspense,
} from 'react';
import { usePathname, useSearchParams } from 'next/navigation';

import "./loader.css"

const PageLoaderContext = createContext<boolean>(false);
const DispatchPageLoaderContext = createContext<Dispatch<boolean>>(
  () => undefined
);

export const useDispatchPageLoader = () => useContext(DispatchPageLoaderContext);

interface PageLoaderProps {
  initialLoading?: boolean;
}
const Loader = () => (
  <div className="absolute w-full top-0 left-0 h-1 overflow-hidden bg-progress">
    <div className="h-full bg-progress-foreground loader-bar"></div>
  </div>
);

const Provider = ({
  children,
  initialLoading = false,
}: PropsWithChildren<PageLoaderProps>) => {
  const [isLoading, setIsLoading] = useState(initialLoading);

  const pathname = usePathname();
  const searchParams = useSearchParams()?.toString();

  useEffect(() => {
    setIsLoading(false);
  }, [pathname, searchParams]);

  return (
    <DispatchPageLoaderContext.Provider value={setIsLoading}>
      <PageLoaderContext.Provider value={isLoading}>
        {isLoading && <Loader />}
        {children}
      </PageLoaderContext.Provider>
    </DispatchPageLoaderContext.Provider>
  );
};

export const PageLoader = ({
  children,
  initialLoading = false,
}: PropsWithChildren<PageLoaderProps>) => (
  <Suspense>
    <Provider initialLoading={initialLoading}>
      {children}
    </Provider>
  </Suspense>
);
