import * as React from "react";
import { cn } from "@/libs/utils";

export type TypographyVariants = 
| "h1"
| "h2"
| "h3"
| "h4"
| "h5"
| "h6"
| "subtitle1"
| "subtitle2"
| "body1"
| "body2"
| "caption"
| "overline";

interface TypographyProps extends React.HTMLAttributes<HTMLElement> {
  variant: TypographyVariants;
  gutterBottom?: boolean;
  children: React.ReactNode;
  as?: 
  | 'p'
  | 'span'
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
}

const variantStyles = {
  h1: "text-5xl font-bold",
  h2: "text-[54px] font-normal leading-[64.8px] text-left",
  h3: "text-3xl font-semibold",
  h4: "text-2xl font-semibold",
  h5: "text-2xl font-normal leading-[28.8px] text-left",
  h6: "text-xl font-normal leading-[24px] text-left",
  subtitle1: "text-base font-[500] leading-[25.6px] tracking-[0.15px] text-left",
  subtitle2: "text-sm font-normal leading-[20.02px] tracking-[0.17px] text-left",
  body1: "text-base font-normal leading-[24px] tracking-[0.15px] text-left",
  body2: "text-base font-normal leading-[24px] tracking-[0.15px] text-left",
  caption: "text-[12px] font-normal leading-[12px] tracking-[0.4px] text-left text-caption-foreground",
  overline: "text-xs uppercase font-medium tracking-wider",
};

const elementMap: Record<TypographyVariants, any> = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  subtitle1: "p",
  subtitle2: "p",
  body1: "p",
  body2: "p",
  caption: "span",
  overline: "span",
};

export const Typography = ({
  variant,
  gutterBottom = false,
  className,
  children,
  as,
  ...props
}: TypographyProps) => {
  const Component = as || elementMap[variant] || "p";

  const combinedClassName = cn(
    variantStyles[variant],
    gutterBottom && "mb-4",
    className
  );

  return (
    <Component className={combinedClassName} {...props}>
      {children}
    </Component>
  );
};
